import { mapState, mapGetters } from 'vuex'

export const computed = {
  ...mapGetters('user', [
    'authUser',
    'appId',
    'authReady',
    'authError',
    'authLoading',
    'requiresTwoFactor'
  ]),
  ...mapState({
    breakpoint: state => state.win.breakpoint,
    theme: state => state.theme
  }),

  signUpLink() {
    const { scope } = this

    if (scope === 'homeowner') {
      return 'signupWithCode'
    } else if (scope === 'vendor') {
      return 'signupWithCodeVendor'
    }

    return ''
  },

  styles() {
    return {
      input: {
        padding: '8px',
        width: '100%',
        background: '#FFF',
        borderBottom: '1px solid #f0f0f3',
        color: '#1c1d21'
      },
      eyeButtons: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '100%',
        transform: 'translateX(-50%) translateY(-50%)'
      },
      appButtons: {
        position: 'relative',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        height: '50px',

        ...(this.breakpoint === 'desktop'
          ? {
              width: '40%'
            }
          : {
              width: '100%'
            }),

        borderRadius: '5px',
        boxShadow: '0 19px 20px 0 rgba(2, 8, 22, 0.2)',
        backgroundColor: '#fff',

        fontSize: '16px',
        fontWeight: 500,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',

        color: '#1a47b0'
      }
    }
  }
}
