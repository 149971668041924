<template>
  <svg
    width="182"
    height="28"
    viewBox="0 0 182 28"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path id="bl5row58fa" d="M.206.257h20.87V21.23H.206z" />
      <path id="5kd0f0mrkc" d="M0 .382h21.384v20.586H0z" />
      <path id="69ywv865le" d="M0 21.23h172.089V.256H0z" />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(0 5.978)">
        <path
          d="m137.02 1.119-4.006 10.548L128.401.94a.787.787 0 0 0-.722-.476h-.028a.787.787 0 0 0-.722.475L122.29 11.67 118.284 1.12a.856.856 0 0 0-.8-.552h-2.78a.856.856 0 0 0-.796 1.172l7.439 18.71c.12.298.409.495.731.495h.239c.314 0 .599-.187.722-.477l4.6-10.76 4.625 10.787a.786.786 0 0 0 .723.477h.237a.787.787 0 0 0 .732-.497l7.438-18.735A.855.855 0 0 0 140.6.567h-2.78a.856.856 0 0 0-.8.552M150.133 13.253h-4.41l2.22-4.868 2.19 4.868zM147.149.983l-9.154 18.757a.855.855 0 0 0 .77 1.23h2.923a.855.855 0 0 0 .781-.506l1.439-3.222h8.01l1.438 3.221a.854.854 0 0 0 .78.507h3.03a.855.855 0 0 0 .769-1.231L148.729.982a.787.787 0 0 0-.707-.44h-.166c-.3 0-.575.171-.707.442zM167.397.97l-4.044 6.492L159.311.97a.856.856 0 0 0-.727-.403h-3.133a.856.856 0 0 0-.713 1.329l6.45 9.714v8.505c0 .473.382.856.854.856h2.622a.855.855 0 0 0 .856-.856v-8.454l6.426-9.768a.855.855 0 0 0-.714-1.326h-3.11a.854.854 0 0 0-.725.403"
          fill="#0F4C98"
        />
        <g transform="translate(23.588)">
          <mask id="re56dv14sb" fill="#fff">
            <use xlink:href="#bl5row58fa" />
          </mask>
          <path
            d="M13.634 16.485c-1.76.986-4.11 1.02-5.949 0-.894-.496-1.617-1.233-2.15-2.193-.538-.97-.811-2.16-.811-3.536 0-1.396.265-2.597.789-3.57.515-.955 1.223-1.686 2.105-2.17.895-.494 1.913-.744 3.022-.744 1.109 0 2.122.25 3.01.742.873.486 1.582 1.218 2.106 2.175.532.972.802 2.171.802 3.567 0 1.378-.27 2.568-.8 3.54-.534.973-1.227 1.69-2.124 2.19zm6.024-11.298a9.736 9.736 0 0 0-3.82-3.658C14.256.685 12.507.257 10.64.257c-1.866 0-3.614.428-5.196 1.272a9.732 9.732 0 0 0-3.82 3.658C.681 6.763.205 8.628.205 10.731c0 2.083.475 3.943 1.417 5.528a9.796 9.796 0 0 0 3.818 3.683c1.582.854 3.33 1.287 5.2 1.287 1.868 0 3.618-.433 5.2-1.287a9.8 9.8 0 0 0 3.818-3.683c.94-1.586 1.417-3.446 1.417-5.528 0-2.102-.477-3.967-1.418-5.544z"
            fill="#0F4C98"
            mask="url(#re56dv14sb)"
          />
        </g>
        <path
          d="M56.565 13.253h-4.41l2.22-4.868 2.19 4.868zM54.455.542h-.167c-.3 0-.574.171-.706.442L44.427 19.74a.855.855 0 0 0 .77 1.23h2.923a.855.855 0 0 0 .781-.506l1.439-3.222h8.01l1.438 3.221a.855.855 0 0 0 .78.507H63.6a.854.854 0 0 0 .768-1.231L55.16.982a.786.786 0 0 0-.707-.44z"
          fill="#0F4C98"
        />
        <mask id="rygadyrtzd" fill="#fff">
          <use xlink:href="#5kd0f0mrkc" />
        </mask>
        <path
          d="m16.644 8.46-5.952-2.98L4.74 8.46V1.237a.855.855 0 0 0-.854-.856H.855A.855.855 0 0 0 0 1.238v18.875c0 .471.383.855.855.855h3.03a.855.855 0 0 0 .854-.855v-6.914l5.953-2.98 5.952 2.98v6.914c0 .471.383.855.856.855h3.028a.856.856 0 0 0 .856-.855V1.238a.855.855 0 0 0-.856-.856H17.5a.855.855 0 0 0-.856.856v7.221z"
          fill="#0F4C98"
          mask="url(#rygadyrtzd)"
        />
        <mask id="wn6a6rczhf" fill="#fff">
          <use xlink:href="#69ywv865le" />
        </mask>
        <path
          fill="#2CABE1"
          mask="url(#wn6a6rczhf)"
          d="M8.327 16.097h2.166V13.93H8.327zM10.872 16.097h2.167V13.93h-2.167zM8.38 18.644h2.167v-2.167H8.38zM10.927 18.644h2.167v-2.167h-2.167zM75.209 10.926l.343.76 4.667 8.864c.136.259.404.42.695.42h.039a.786.786 0 0 0 .695-.418l4.707-8.898.225-.573 2.027 9.218c.087.392.434.67.835.67h2.564a.855.855 0 0 0 .835-1.037L88.728 1.185a.787.787 0 0 0-.768-.618h-.15a.788.788 0 0 0-.701.43l-5.215 10.248-.96 2.087-.975-2.117L74.65.992a.786.786 0 0 0-.698-.425h-.177a.786.786 0 0 0-.769.624L69.05 19.938a.855.855 0 0 0 .836 1.032h2.589a.855.855 0 0 0 .838-.685l1.896-9.359zM100.165 7.462 96.122.97a.855.855 0 0 0-.726-.403h-3.134a.855.855 0 0 0-.712 1.329l6.449 9.714v8.505c0 .473.382.855.855.855h2.623a.855.855 0 0 0 .855-.855v-8.454l6.426-9.768a.856.856 0 0 0-.715-1.326h-3.11a.853.853 0 0 0-.724.403l-4.044 6.492z"
        />
      </g>
      <text
        font-family="Poppins-SemiBold, Poppins"
        font-size="8"
        font-weight="500"
        fill="#0F4C98"
        transform="translate(0 .584)"
      >
        <tspan x="174.371" y="9">™</tspan>
      </text>
    </g>
  </svg>
</template>

<script>
export default {}
</script>
