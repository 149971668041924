export function data() {
  return {
    email: '',
    password: '',
    isDebug: true,
    scope: '',
    toggle: false,
    passShowing: false,
    rememberUsername: false
  }
}
