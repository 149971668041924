<template>
  <main
    region="main"
    class="sign-in"
    :style="{
      alignItems: 'normal',
      minHeight: '100vh',
      position: 'relative'
    }"
  >
    <div
      class="Gradient-bg"
      :style="{
        position: 'absolute',
        height: '100%',
        width: '100%',
        overflow: 'hidden'
      }"
    >
      <Cloud1
        :style="{
          position: 'absolute',
          right: '-34%',
          bottom: '-25%',
          width: '90%',
          height: '120%',
          opacity: '.7'
        }"
      />

      <Cloud2
        :style="{
          position: 'absolute',
          right: '-34%',
          bottom: '-25%',
          width: '90%',
          height: '120%',
          opacity: '.7'
        }"
      />
    </div>

    <div
      :style="{
        position: 'absolute',

        ...(breakpoint === 'desktop'
          ? {
              width: '60%',
              minWidth: '500px'
            }
          : {
              width: '100%',
              minWidth: 'initial'
            }),

        height: '100%',
        borderTopRightRadius: '16px',
        borderBottomRightRadius: '16px',
        backgroundColor: '#fff',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }"
    ></div>

    <div
      :style="{
        position: 'relative',

        paddingTop: '100px',

        ...(breakpoint === 'desktop'
          ? {
              width: '60%',
              minWidth: '500px'
            }
          : {
              width: '100%',
              minWidth: 'initial'
            }),

        borderRadius: '16px',

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }"
    >
      <div
        :style="{
          width: '80%',
          marginBottom: '50px'
        }"
      >
        <Logo
          :style="{
            position: 'relative'
          }"
        />
      </div>

      <span
        class="Welcome-Lets-Get-Y"
        :style="{
          position: 'relative',
          width: '80%',
          marginBottom: '50px'
        }"
      >
        <p v-if="scope === 'homeowner'" class="text-style-1">Welcome to the Homeowner Portal</p>
        <p v-if="scope === 'management'" class="text-style-1">Welcome to the Board Portal</p>
        <p v-if="scope === 'vendor'" class="text-style-1">Welcome to the Vendor Portal</p>
        <p v-if="scope === 'onboarding'" class="text-style-1">Welcome to the Onboarding Portal</p>

        <p>Let’s Get You Started!</p>
      </span>

      <div
        class="form-box"
        v-if="authReady && !authUser"
        :style="{
          margin: 0,
          padding: 0,
          width: '80%',
          maxWidth: 'initial'
        }"
      >
        <section id="sign-in-form" @submit.prevent="onSubmit">
          <b-field label="Username" :style="{ position: 'relative', color: '#777777' }">
            <input
              :placeholder="$t('auth.email')"
              v-model="email"
              type="email"
              icon="email"
              tests-id="email"
              :style="styles.input"
            />
          </b-field>

          <b-field label="Password" :style="{ position: 'relative', color: '#777777' }">
            <input
              password-input
              v-model="password"
              v-on:keyup.enter="onSubmit"
              tests-id="password"
              :placeholder="$t('auth.password')"
              :type="passShowing ? 'text' : 'password'"
              icon="lock"
              autocomplete="current-password"
              :style="styles.input"
            />

            <button
              pass-toggle
              @click="togglePass"
              aria-hidden="true"
              :style="{
                position: 'absolute',
                width: '45px',
                height: '45px',
                right: 0,
                bottom: 0
              }"
            >
              <Transition name="fade">
                <Eye v-if="!passShowing" :style="styles.eyeButtons" />
              </Transition>

              <Transition name="fade">
                <EyeFull v-if="passShowing" :style="styles.eyeButtons" />
              </Transition>
            </button>
          </b-field>

          <div class="field">
            <transition name="fade" appear>
              <div
                class="alert alert-warning"
                role="alert"
                v-if="authError"
                :style="{
                  color: theme.color[4]
                }"
              >
                {{ authError }}
              </div>
            </transition>
          </div>

          <div
            :style="{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '8px 0'
            }"
          >
            <b-field :style="{ margin: 0 }">
              <b-checkbox v-model="rememberUsername">Remember my username</b-checkbox>
            </b-field>

            <div>
              <router-link
                v-if="['homeowner', 'vendor'].includes(scope)"
                :to="{
                  name: signUpLink
                }"
                class="href-overflow-wrap is-primary is-medium router-link-a"
                >New User Registration</router-link
              >
            </div>
          </div>

          <div
            :style="{
              display: 'flex',
              justifyContent: 'end',
              padding: '8px 0 16px'
            }"
          >
            <router-link
              :to="{
                name: 'forgotpassword'
              }"
              class="href-overflow-wrap is-primary is-medium router-link-a"
              >Forgot Password</router-link
            >
          </div>

          <button
            class="button is-primary is-pulled-right"
            :disabled="authLoading"
            tests-id="sign-in-button"
            :style="{
              width: '100%',
              borderRadius: '5px',
              boxShadow: '0 40px 40px 0 rgba(184, 184, 184, 0.3)',
              backgroundImage: 'linear-gradient(to right, #2cabe1, #398ce5)'
            }"
            @click="signIn"
          >
            SIGN IN NOW
          </button>
        </section>
      </div>

      <div
        :style="{
          display: 'flex',
          margin: '30px 0',
          width: '80%',
          justifyContent: 'space-between',

          ...(breakpoint === 'desktop'
            ? {}
            : {
                flexDirection: 'column'
              })
        }"
      >
        <b-tooltip
          label="Not yet implemented"
          :style="
            Object.assign({}, styles.appButtons, {
              boxShadow: 'initial'
            })
          "
        >
          <button
            :style="
              Object.assign({}, styles.appButtons, {
                width: '100%',
                opacity: '.3'
              })
            "
            aria-label="download iOS app"
            disabled
          >
            <span>Download App</span>
            <Apple :style="{ marginLeft: '8px' }" />
          </button>
        </b-tooltip>

        <!-- once implemented
        <button 
          :style="Object.assign ({}, styles.appButtons)"
          aria-label="download iOS app"
          disabled
        >
          <span>Download App</span>
          <Apple :style="{ marginLeft: '8px' }" />
        </button>
        -->

        <div
          :style="{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            height: '50px'
          }"
        >
          <span>Or</span>
        </div>

        <b-tooltip
          label="Not yet implemented"
          :style="
            Object.assign({}, styles.appButtons, {
              boxShadow: 'initial'
            })
          "
        >
          <button
            :style="
              Object.assign({}, styles.appButtons, {
                width: '100%',
                opacity: '.3'
              })
            "
            aria-label="download Android app"
            disabled
          >
            <span :style="{ paddingLeft: '8px' }">Download App</span>
            <Android :style="{ marginLeft: '8px' }" />
          </button>
        </b-tooltip>

        <!-- once implemented
        <button 
          :style="Object.assign ({}, styles.appButtons)"
          aria-label="download Android app"
        >
          <span :style="{ paddingLeft: '8px' }">Download App</span>
          <Android :style="{ marginLeft: '8px' }" />
        </button>
        -->
      </div>

      <div :style="{ marginBottom: '20px', marginTop: '20px' }">
        <div
          role="img"
          aria-label="Equal Housing Opportunity Icon"
          tabindex="0"
          :style="{
            background: 'linear-gradient(to bottom, #123075, #092056)',
            borderRadius: '11px'
          }"
        >
          <!-- Hiding fair housing logo until confirmed that it's needed -->
          <img
            :style="{
              height: breakpoint === 'mobile' ? '60px' : '120px',
              display: 'none'
            }"
            src="@/assets/images/CCRM-Fair-Housing-wht-150x150.png"
            alt="Equal Housing Opportunity Icon"
          />
        </div>
      </div>

      <!--
      <div class="form-box">
        <h5>Not yet registered?</h5>
        <router-link to="/register" class="button">Create account</router-link>
      </div> -->
      <Modal :toggle.sync="toggle">
        <TwoFactorSigninModal :requiresTwoFactor="requiresTwoFactor" />
      </Modal>
    </div>

    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,

        ...(scope === 'homeowner'
          ? {
              height: '60%',
              width: '60%'
            }
          : {
              height: '56%',
              width: '56%'
            }),

        overflow: 'hidden',

        pointerEvents: 'none'
      }"
    >
      <Transition name="fade">
        <House
          v-if="breakpoint === 'desktop' && scope === 'homeowner'"
          :style="{
            position: 'relative',

            right: '-50px',
            bottom: '-30px',

            height: '100%',
            width: '100%'
          }"
        />
        <Meeting
          v-if="breakpoint === 'desktop' && scope === 'management'"
          :style="{
            position: 'relative',

            right: '-50px',
            bottom: '-30px',

            height: '100%',
            width: '100%'
          }"
        />
      </Transition>
    </div>
  </main>
</template>

<script type="es6">
import Modal from '@/components/Modal'
import TwoFactorSigninModal from '@/components/TwoFactorSignin'


import { computed } from './parts/computed'
import { data } from './parts/data'
import { methods } from './parts/methods'
import { mounted } from './parts/mounted'
import { watch } from './parts/watch'

import Logo from '@/components/Logo'

import Meeting from './components/Meeting'
import House from './components/House'
import Cloud2 from './components/Cloud2'
import Cloud1 from './components/Cloud1'

import Android from '@/components/icons/Android'
import Apple from '@/components/icons/Apple'
import Eye from '@/components/icons/Eye'
import EyeFull from '@/components/icons/EyeFull'

export default {
  components: {
    Apple,
    Android,
    Cloud1,
    Cloud2,
    Eye,
    EyeFull,
    House,
    Logo,
    Meeting,
    Modal,
    TwoFactorSigninModal
  },

  metaInfo: {
    title: 'Sign In'
  },

  computed,
  data,
  methods,
  watch,

  mounted,


}
</script>

<style lang="scss" scoped>
.router-link-a {
  color: #2cabe1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.Path-Copy {
  width: 690.9px;
  height: 765.7px;
  margin: 283.7px 0 185.6px 749.1px;
  transform: rotate(-233deg);
  opacity: 0.2;
  background-color: #1b51e5;
}

.form-box {
  max-width: 480px;
}

.sign-in {
  display: flex;
  height: 100%;
  min-height: 75vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Gradient-bg {
  width: 1438px;
  height: 1235px;

  margin: 0 2px 0 0;

  padding: 0 34.2px 0 0;

  background-image: linear-gradient(345deg, #2662d6 24%, #133a86 68%);
}

.Welcome-Lets-Get-Y {
  font-family: Helvetica;
  font-size: 39px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;

  line-height: 1.08;
  letter-spacing: normal;

  color: #112d6f;
}

.Welcome-Lets-Get-Y .text-style-1 {
  font-weight: normal;
}
</style>
