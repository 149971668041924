import { mapActions } from 'vuex'
import Cookies from 'js-cookie'

export const methods = {
  ...mapActions('user', ['login', 'loginCheckMultiFactor']),

  checkForUser(value) {
    if (value !== null && value !== undefined) {
      this.$router.replace(this.$route.query.nextUrl || '/?fromsignin=true')

      //Necessary to force a default unit selection after a "nocheck" router push
      setTimeout(() => {
        window.location.reload()
      }, 200)
    }
  },

  togglePass(e) {
    e.preventDefault()
    e.stopPropagation()

    this.passShowing = !this.passShowing
  },

  signIn(e) {
    e.preventDefault()
    e.stopPropagation()

    const { rememberUsername, email, scope } = this

    if (rememberUsername) {
      Cookies.set(`${scope}-email`, email, {
        secure: true,
        sameSite: 'None'
      })
    }

    this.onSubmit()
  },

  async onSubmit() {
    const { email, password } = this
    this.toggle = false
    this.loginCheckMultiFactor({ email, password }).then(async () => {
      if (this.isDebug === true)
        console.debug('requiresTwoFactor=' + JSON.stringify(this.requiresTwoFactor))

      if (
        this.requiresTwoFactor &&
        this.requiresTwoFactor != undefined &&
        this.requiresTwoFactor.length > 0
      ) {
        this.toggle = true
      } else {
        await this.login({ email, password })
      }
    })
  },

  autoLogin() {
    if (this.$route.query.unpw !== undefined && this.$route.query.unpw) {
      var userNamePasswordInBaseSixtyFour = this.$route.query.unpw

      if (userNamePasswordInBaseSixtyFour) {
        var decoded = atob(userNamePasswordInBaseSixtyFour)

        if (decoded) {
          var sepPos = decoded.indexOf('|')

          if (sepPos > -1) {
            this.email = decoded.substr(0, sepPos)
            this.password = decoded.substr(sepPos + 1)

            if (this.email && this.password) {
              this.onSubmit()
            }
          }
        }
      }
    }
  }
}
