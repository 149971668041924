import { appId } from '@/config'
import Cookies from 'js-cookie'

export function mounted() {
  const { scope } = this

  this.email = Cookies.get(`${scope}-email`) || ''

  this.checkForUser(this.authUser)

  const newRegistrationEmail = this.$route.query.email
  if (newRegistrationEmail && newRegistrationEmail != undefined) {
    this.email = newRegistrationEmail
  }

  this.scope = appId()

  this.autoLogin()
}
